import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import queryString from 'query-string';
import { Layout, Slice, ScrollToTop, CityGrid } from 'components';
import * as styles from './page.module.scss';
import washedAiData from '../helpers/washAiData';
import scrollToElement from '../helpers/scrollToElement';

const AiPersonalLoan = ({ location, data }) => {
  const { prismicPage, googlePersonalLoanProductsSheet: aiData } = data;
  const { data: pageData, uid, tags } = prismicPage;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    navigation_cta_link: ctaLink,
    navigation_cta_text: ctaText,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const isFaq = location?.pathname === '/faq/';
  const isProduct = location?.pathname.includes('product') ? styles.product : '';
  const isCity = tags?.includes('City');

  useEffect(() => {
    const queryStrings = location && location.search ? queryString.parse(location.search) : {};
    const { anchor } = queryStrings;
    if (anchor) scrollToElement(anchor);
  }, [location]);

  const navLink = { url: ctaLink?.url, text: ctaText?.text };
  return (
    <Layout location={location} seo={washedAiData(seo, aiData)} uid={uid} navLink={washedAiData(navLink, aiData)}>
      <div className={`${isProduct}`}>
        {sliceData.map((slice) => (
          <Slice key={slice.id} data={washedAiData(slice, aiData)} location={location} isPage />
        ))}
        {isFaq && <ScrollToTop />}
        {isCity && <CityGrid currentPage={uid} />}
      </div>
    </Layout>
  );
};

export default AiPersonalLoan;

export const pageQuery = graphql`
  query AiMakeBySlug($uid: String!) {
    googlePersonalLoanProductsSheet(slug: { eq: $uid }) {
      slug
      product
      productcta
      text1
      text2
      text3
      headerImage
      fields {
        headerImage {
          url
          childrenImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    prismicPage(uid: { eq: "personal-loan-product" }) {
      id
      uid
      prismicId
      tags
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        navigation_cta_link {
          url
        }
        navigation_cta_text {
          html
          text
        }
        body {
          ...HeaderPageFragment
          ...TwoColsFeatureListPageFragment
          ...PartnersGridPageFragment
          ...FaqAccordionPageFragment
          ...TrustPilotPageFragment
          ...CtaPageFragment
          ...TwoColsTextPageFragment
          ...CustomerStoriesPageFragment
          ...TextBlockPageFragment
          ...InfoTextPageFragment
          ...AnchorButtonsPageFragment
          ...ProductPageFragment
          ...PageAnchorPoint
          ...ThreeColsReviewsPageFragment
          ...LenderTablePageFragment
          ...PageDotPointTips
          ...WysiwygPageFragment
          ...BreakoutBoxPageFragment
          ...LenderInfoPageFragment
          ...TwoColsLoanCardPageFragment
          ...IconColumnsPageFragment
          ...PageComparisonTable
          ...iframeEmbedFragment
          ...ImageContactStepsPageFragment
          ...TextImageOverlapPageFragment
          ...SignUpFormPageFragment
          ...CalculatorPageFragment
          ...DocumentsPageFragment
          ...StackedComparisonPageFragment
          ...TwoColsImagePageFragment
          ...StatsPageFragment
          ...TickertapePageFragment
          ...LenderBubblesPageFragment
          ...ServiceBundlesPageFragment
        }
      }
    }
    partners: prismicSettings {
      data {
        referral_partners {
          id {
            text
          }
          logo {
            url
          }
        }
      }
    }
  }
`;
